import React from 'react'

import classNames from 'classnames'
import DefaultLayout from '../../layout/default'

import CtaForm from '../../pagesSections/index/CtaForm'

import pageStyles from '../../styles/_V2/pagesStyles/styles.module.scss'
import styles from '../styles/_V2/docs.module.scss'
import AdaptiveLink from '../../components/AdaptiveLink'
import { pagesLinks } from '../../pages-data/common/links'

export default function PagePrivacyPolicy() {
  const metaTags = [
    // {
    //   name: 'keywords',
    //   content: 'Автоматизация ресторана, автоматизация кафе, автоматизация бара, автоматизация столовой, ipad pos, касса на ipad, калькуляция, расчет себестоимости, технологическая карта, российская система автоматизации',
    // },
    {
      name: 'description',
      content: 'Лицензионный договор является офертой ООО «Квик Ресто» — система автоматизации предприятий общественного питания Quick Resto — программа для полноценного складского учёта в общепите онлайн, удобный кассовый терминал на планшете, CRM и настраиваемая система отчетов.',
    },
    {
      property: 'og:url',
      content: 'https://quickresto.ru/licenseAgreement/annex2',
    },
    {
      property: 'og:title',
      content: 'Лицензионный договор на право использования системы автоматизации Quick Resto',
    },
    {
      property: 'og:description',
      content: 'Лицензионный договор является офертой ООО «Квик Ресто» — система автоматизации предприятий общественного питания Quick Resto — программа для полноценного складского учёта в общепите онлайн, удобный кассовый терминал на планшете, CRM и настраиваемая система отчетов.',
    },
    {
      property: 'og:type',
      content: 'website',
    },
  ]

  return (
    <DefaultLayout
      title="Приложение № 2"
      metaTags={metaTags}
      mainWrapperClassName={classNames(
			  pageStyles.pageWrapper,
			  pageStyles.pageGray,
			  styles.docs,
      )}
      linkCanonical="https://quickresto.ru/licenseAgreement/annex2/"
      footerClassName={pageStyles.pageSection}
    >
      <section
        className={classNames(styles.docs__container, pageStyles.pageSection)}
      >
        <div className={styles.docs__content}>
          <h1 className={styles.bigMb}>
            Специальные условия Лицензии на Опциональную программу -  «Брендированный клиентский сайт» входящую в пакет Опциональных программ для ЭВМ «Лояльность.360»
          </h1>

          <div>
            <p>
              Приложение № 2
              к Лицензионному договору на право использования
              программы для ЭВМ «Система автоматизации предприятий
              общественного питания «Quick Resto»» и иных
              программных продуктов ООО «Квик Ресто»
            </p>

          </div>

          <div>
            <p />

            <p>1. Настоящее Приложение, как и Лицензионный договор, является офертой <br />ООО&nbsp;&laquo;Квик
              Ресто&raquo;&nbsp;(ОГРН 5137746099046, ИНН&nbsp;7726734798, КПП 771401001, 125167 <br />г. Москва,
              Ленинградский пр. 36, стр. 30, пом. 96), именуемого в дальнейшем Лицензиар, Пользователю, именуемому в
              дальнейшем Лицензиат или Оператор персональных данных. <br />2. Под офертой согласно настоящему Приложению
              № 2 подразумевается предложение Лицензиара адресованное, имеющему лицензию на основную Программу
              Лицензиату, приобрести на срок не превышающий срок лицензии основного Программного продукта, за
              дополнительное вознаграждение Пакет опциональных программ для ЭВМ &laquo;Лояльность.360&raquo; в частности
              входящую в указанный пакет Опциональную программу &laquo;Брендированный клиентский
              сайт&raquo; (далее &ndash; Сервис &laquo;Клиентский сайт&raquo;). на основании простой (неисключительной)
              лицензии на результат интеллектуальной деятельности ООО &laquo;Квик Ресто&raquo; &ndash; разработанную
              Лицензиаром программу для ЭВМ &laquo;Брендированный клиентский
              сайт&raquo; (далее &ndash; Сервис &laquo;Клиентский сайт&raquo;), которую Лицензиар в соответствии с
              требованиями Лицензиата визуально адаптирует под его деятельность и которая предназначается для
              использования клиентами Лицензиата.</p>
            <p>Сервис &laquo;Клиентский сайт&raquo; позволяет Лицензиату на основе макета, имеющегося в
              Сервисе &laquo;Клиентский сайт&raquo;, самостоятельно формировать (наполнять содержимым) и редактировать
              свой сайт в сети интернет, в том числе создавать на своем сайте электронное меню предлагаемых Лицензиатом
              блюд и напитков с указанием их названия, описания состава и стоимости, отображать текущие акции в
              заведении с указанием описания, периода действия , а также позволяет клиентам Лицензиата оформлять
              доставку блюд и напитков в соответствии с их заказами, сформированными дистанционным способом на основании
              электронного меню на сайте Лицензиата.</p>
            <p>Простая (неисключительная) лицензия на результат интеллектуальной деятельности ООО &laquo;Квик
              Ресто&raquo; &ndash; разработанную Лицензиаром программу для ЭВМ &laquo;Брендированный клиентский
              сайт&raquo; <br />(далее &ndash; Сервис &laquo;Клиентский сайт&raquo;), реализуется исключительно в пакете
              опциональных программ для ЭВМ &laquo;Лояльность.360&raquo; и исключительно пользователям имеющим Лицензию
              на основную программу и исключительно на срок не более чем срок лицензии основного программного продукта.
            </p>
            <p>Акцепт на указанную в п.2 настоящего Приложения оферту возможен при одновременном соблюдении 4
              условий:</p>
            <p>1) Лицензиат приобретающий Лицензию на Сервис &laquo;Клиентский сайт&raquo; в пакете Опциональных
              программ для ЭВМ &laquo;Лояльность.360&raquo; должен иметь Лицензию на основной программный продукт
              (Программу)<br />2) Лицензиат приобретает Лицензию на Сервис &laquo;Клиентский сайт&raquo; в Пакете
              Опциональных программ для ЭВМ &laquo;Лояльность.360&raquo; со всеми иными входящими в указанный Пакет
              опциональными программами.<br />3) Оплата вознаграждения за пакет опциональных программ для
              ЭВМ &laquo;Лояльность.360&raquo; по счету, выставленному Лицензиаром Лицензиату в соответствии с Тарифным
              планом, выбранным Лицензиатом<br />4) Лицензиат приобретает Лицензию на Сервис &laquo;Клиентский
              сайт&raquo; в Пакете Опциональных программ для ЭВМ &laquo;Лояльность.360&raquo; на срок не более чем срок
              действующей лицензии на основной программный продукт.</p>
            <p>При не соблюдении любого из вышеуказанных пунктов &ndash; оферта не считается акцептованной</p>
            <p>2.1. Сервис &laquo;Клиентский сайт&raquo; является универсальным программным продуктом и предоставляется
              пользователям (Лицензиатам) в том виде &laquo;как есть&raquo;. <br />3. Лицензиар гарантирует Лицензиату,
              что он является законным правообладателем программы для ЭВМ - &laquo;Брендированный клиентский сайт&raquo;
            </p>
            <p>4. Срок действия Лицензии на Пакет опциональных программ &laquo;Лояльность.360&raquo; в частности
              входяшую в указанный пакет Опциональную программу Сервис &laquo;Клиентский сайт&raquo; и срок доступа к
              Опциональной программе Сервис &laquo;Клиентский сайт&raquo; входящей Пакет опциональных программ для
              ЭВМ &laquo;Лояльность.360&raquo;, определяются в соответствии с Разделом 2 и Разделом 4 Лицензионного
              договора на право использования программы для ЭВМ &laquo;Система автоматизации предприятий общественного
              питания &laquo;Quick Resto&raquo; и иных программных продуктов ООО &laquo;Квик
              Ресто&raquo; (далее &ndash; &laquo;Договор&raquo; или &laquo;Лицензионный договор&raquo;).<br />По итогам
              каждого периода использования прав на Пакет опциональных программ для ЭВМ &laquo;Лояльность.360&raquo; в
              частности входяшую в указанный пакет Опциональную программу Сервис &laquo;Клиентский сайт&raquo; Лицензиар
              направляет на электронную почту, указанную Лицензиатом, акт исполнения обязательств по настоящему
              Договору. При отсутствии у Лицензиата в течение 3 (трех) календарных дней с момента направления ему
              данного акта и отсутствия мотивированных возражений, данный акт считается согласованным и подписанным
              Лицензиатом, а услуги принятыми без претензий к качеству и срокам.</p>
            <p>5. В дальнейшем продление Лицензии на Пакет опциональных программ для ЭВМ &laquo;Лояльность.360&raquo; в
              частности входяшую в указанный пакет Опциональную программу Сервис &laquo;Клиентский сайт&raquo; и оплата
              вознаграждения за предоставление Лицензии на Пакет опциональных программ для
              ЭВМ &laquo;Лояльность.360&raquo; в частности входяшую в указанный пакет Опциональную программу
              Сервис &laquo;Клиентский сайт&raquo; осуществляется на условиях определяются в соответствии с Разделом 2 и
              Разделом 4 Лицензионного договора на право использования программы для ЭВМ &laquo;Система автоматизации
              предприятий общественного питания &laquo;Quick Resto&raquo; и иных программных продуктов ООО &laquo;Квик
              Ресто&raquo; (далее &ndash; &laquo;Договор&raquo; или &laquo;Лицензионный договор&raquo;).</p>
            <p>6. Сервис &laquo;Клиентский сайт&raquo; размещается в интернет на домене Лицензиара по адресу: <br />в
              формате https://название_заведения.menuquickresto.ru/ , где название заведения предоставляется клиентом,
              либо привязывается к домену клиента (названию облака), при оплате соответствующей услуги.</p>
            <p>7. Во всем ином, не предусмотренном настоящим Приложением № 2, применяются положения Лицензионного
              договора, а также положения действующего законодательства Российской Федерации.</p>
            <p>8. Краткое описание Сервиса &laquo;Клиентский сайт&raquo; и возможностей данной программы, а также
              преимуществ содержится по следующей интернет ссылке: https://quickresto.ru/emenu/ и
              https://quickresto.ru/wl/</p>
            <p>9. Лицензиат подтверждает, что он:<br />&middot;&nbsp;перед заключением настоящего Приложения № 2 в
              полном объеме ознакомлен с функционалом (возможностями) Сервиса &laquo;Клиентский сайт&raquo;;<br />-
              перед заключением настоящего Приложения № 2 в полном объеме ознакомлен с текстом Лицензионного договора на
              право использования программы для ЭВМ &laquo;Система автоматизации предприятий общественного
              питания &laquo;Quick Resto&raquo;&raquo; и иных программных продуктов ООО &laquo;Квик Ресто&raquo;, в
              котором содержатся все основные условия предоставления лицензий на программные продукты ООО &laquo;Квик
              Ресто&raquo; и который содержится по следующей интернет ссылке:
              https://quickresto.ru/licenseAgreement/;<br />- соглашается с тем, что:<br />С 01.09.2024 г. новые
              Лицензиаты (новые клиенты) не имеют возможность приобрести лицензию на пакет Опциональных программ для
              ЭВМ &laquo;Лояльность.360&raquo; без приобретения Программы.<br />Действующие Лицензиаты, имеющие
              оплаченные/приобретенные Лицензии на опциональные программы на 01.09.2024 г. (до введения пакета
              Опциональных программ для ЭВМ &laquo;Лояльность.360&raquo;) пользуются лицензиями до момента окончания
              своих действующих лицензий на опциональные программы, после окончания срока таких лицензий возможность
              приобрести/продлить опциональные программы без покупки основной программы не допускается. <br />С
              01.09.2024 г. срок действия лицензии на пакет Опциональных программ для
              ЭВМ &laquo;Лояльность.360&raquo; не может быть больше, чем срок действия лицензии на основную Программу,
              указанное правило не распространяется на уже действующие лицензии.<br />- Уведомлен об отсутствии, в связи
              с изменением тарифной политики, возможности приобрести Сервис &laquo;Клиентский сайт&raquo; отдельно от
              опциональных программ из Пакета Опциональных программ для ЭВМ &laquo;Лояльность.360&raquo;, <br />-
              Предупрежден о том, что без приобретения Лицензии на основную Программу/в случае окончания лицензии на
              основную Программу, опциональные программы будут работать в ограниченном режиме, с ограниченным
              функционалом/нестабильно и Лицензиар не несет ответственности за такие действия, так как это не зависит от
              действий Лицензиара, а связано с техническими особенностями Программы и Опциональных программных продуктов<br />-
              осознает, что Программа является основным продуктом Лицензиара, без которого использование любой
              опциональной программы из пакета Опциональных программ для ЭВМ &laquo;Лояльность.360&raquo; в полном
              объеме будет функционально ограничено, в виду того, что опциональные программы являются вторичными
              (дополнительными) к Программе и внедряются в основную Программу, позволяя расширить ее функционал, а
              именно в панель управления Программой. В отсутствие приобретенной лицензии на основную Программу, основные
              разделы/модули в панели не будут доступны/будут работать нестабильно, что повлияет на работу Опциональных
              программ.<br />-&nbsp;осознаёт, что Сервис &laquo;Клиентский сайт&raquo; является универсальным
              программным продуктом и предоставляется пользователям (Лицензиатам) в том виде &laquo;как
              есть&raquo;, <br />- осознает, что при неуплате продления Лицензии на пакет опциональных программ для
              ЭВМ &laquo;Лояльность.360&raquo; куда входит Сервис &laquo;Клиентский сайт&raquo;, Лицензиар вправе
              отключить, а затем удалить Сервис &laquo;Клиентский сайт&raquo; и отключить функционал настройки в
              облачном бэк-офисе Лицензиата;<br />- заключает настоящее Приложение № 2, так как убедился в том, что
              Сервис &laquo;Клиентский сайт&raquo; в полной мере соответствует потребностям и ожиданиям
              Лицензиата.<br />- Не несет расходы на хранение данных Лицензиата, а также техническую поддержку,
              исключительно на период действия оплаченной Лицензии.</p>
            <p>&middot; осознаёт, что, используя функциональные возможности Сервиса &laquo;Клиентский сайт&raquo;,
              Лицензиат самостоятельно формирует (наполняет содержимым) и редактирует свой сайт в сети интернет;</p>
          </div>
        </div>
      </section>

      <CtaForm
        isFry={false}
        className={pageStyles.pageSection}
        isWhiteBackground
      />
    </DefaultLayout>
  )
}
